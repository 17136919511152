<template>
  <div v-html="html" class="text-format"></div>
</template>

<script>
export default {
  props: {
    html: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.text-format {
  text-align: left;
  margin-bottom: 100px;
  ::v-deep {
    h1 {
      font-size: 22px;
      text-align: center;
      margin-bottom: 50px;
    }
    h2 {
      margin-top: 2em;
      font-size: 22px;
    }
    h3 {
      font-size: 18px;
      margin-top: 1.5em;
      margin-bottom: 1em;
      font-weight: bold;
    }
    p, li {
      font-size: 18px;
      text-align: justify;
    }
    ul {
      margin-bottom: 1em;
    }
    li {
      margin-bottom: 0.5em;
      &:before {
        content: "- ";
      }
    }
  }
}
</style>
