<template>
  <TextFormat :html="conditionsHtml" class="conditions"></TextFormat>
</template>

<script>
import TextFormat from '@/components/large-text/TextFormat.vue'
import conditionsHtml from '@/documents/conditions.dirt.html'

export default {
  data () {
    return {
      conditionsHtml
    }
  },
  components: {
    TextFormat
  }
}
</script>

<style lang="scss" scoped>
</style>
