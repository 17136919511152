<template>
  <TextFormat :html="legalsHtml" class="legals"></TextFormat>
</template>

<script>
import TextFormat from '@/components/large-text/TextFormat.vue'
import legalsHtml from '@/documents/legals.dirt.html'

export default {
  data () {
    return {
      legalsHtml
    }
  },
  components: {
    TextFormat
  }
}
</script>

<style lang="scss" scoped>
</style>
