<template>
  <TextFormat :html="rulesHtml" class="rules"></TextFormat>
</template>

<script>
import TextFormat from '@/components/large-text/TextFormat.vue'
import rulesHtml from '@/documents/rules.dirt.html'

export default {
  data () {
    return {
      rulesHtml
    }
  },
  components: {
    TextFormat
  }
}
</script>

<style lang="scss" scoped>
</style>
